import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetTeamListQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import HeadTags from "shared/HeadTags";
import { EmployeeItem } from "services/api/types/News";
import ym from 'react-yandex-metrika';
import ep from "assets/images/team_ep.jpg"

const TeamListItem = ({person}: {person: EmployeeItem}) => {
	return (
		<li className="main-team-item">
			<Link to={`/komanda/${person.slug}`} className="main-team-item__photo">
				<img src={person.thumbnail} alt={`${person.name} ${person.surname}`}/>
				<span className="main-team-item__name">{person.name} <span className="main-team-item__name-right">{person.surname}</span></span>
			</Link>
			<Link to={`/komanda/${person.slug}`} className="team-list-item__desc">
				<span className="team-list-item__position">{person.position}</span>
				<span className="main-team-item__more gradient btn">ПОДРОБНЕЕ</span>
			</Link>
		</li>
	)
};

const TeamListPage = ({setPageClass}) => {
	const { data, isLoading } = useGetTeamListQuery();
	const navigate = useNavigate();

	useEffect(() => {
        setPageClass('team-page');
		ym('hit', `/komanda`);
		const _tmr = window['_tmr'] || (window['_tmr'] = []);
    	_tmr.push({ id: "3474920", type: "pageView", url: window.location.href });
    }, []);
	useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

	const teamList = data
		? data.length
			? <>
			<ul className="team-list">
				{data.filter(person => person.surname !== "Полянская").map(person => {
					return (
						<TeamListItem key={person.id} person={person} />
					)
				})}
			</ul>
			<p className="team-list_notice">Данная информация размещена с согласия объектов персональных данных.</p>
			</>
			: null
		: <p>Загрузка ...</p>;

	return (
		<section className="team container">
			<HeadTags
				title='Команда Елены Полянской - ведушие психологи'
				metaDescription='Команда Елены Полянской приветствует Вас. Специалисты нашей команды имеют высшее психологическое образование и прошли обучение на курсах психотерапии.' />
			<ul className="breadcrumbs">
                <li><Link to="/">главная</Link></li>
                <li><span>Команда</span></li>
            </ul>
			<h1 className="h2">команда</h1>
			<div className="team__text">
				<p>Здравствуйте! Команда Елены Полянской приветствует вас. Наши специалисты имеют высшее профильное образование и прошли дополнительное спецобучение.</p>
				<p>Мы готовы помочь вам разобраться в семейных, партнерских и детско-родительских отношениях. Мы знаем способы наладить контакт с самим собой и строить жизнь, опираясь на свои ориентиры и ценности. Мы даем техники и методики, позволяющие решать возникающие на жизненном пути задачи.</p>
				<p>Мы предлагаем широкий выбор курсов и вебинаров, которые помогут вам разобраться в сложных вопросах. Вы можете выбрать программу, которая соответствует вашим потребностям и интересам.</p>
			</div>
			<div className="team__main">
				<img src={ep} className="team__main-photo" alt="Елена Полянская" />
				<div className="about__block">
					<div className="about__block-content">
						<h2 className="about__title">Елена <span>Полянская</span></h2>
						<p>Психолог, работающий с человеческими чувствами, проблемами, болезнями.</p>
						<p>Работаю через контакт с чувствами, телом, семьей, родом и большими процессами, затрагивающими человека. Работаю на исцеление, которое гармонизирует и тело, и душу.</p>
						<Link to="/elena-polyanskaya" className="team__main-link btn gradient">ПОДРОБНЕЕ</Link>
					</div>
				</div>
			</div>
			<LoadingBlock isLoading={isLoading}>
				<>
					<h2>Ведущие психологи</h2>
					{teamList}
				</>
			</LoadingBlock>
		</section>
	)
}
export default TeamListPage;