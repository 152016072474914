import { Link } from 'react-router-dom';
import Menu from 'features/Menu/Menu';

const logoImg = require("assets/images/logo.svg").default;
const telegram = require("assets/images/telegram.svg").default;
const youtube = require("assets/images/youtube.svg").default;
const whatsapp = require("assets/images/whatsapp.svg").default;
const vkontakte = require("assets/images/vkontakte.svg").default;

const Footer = () => {
  return (
    <footer className="container" itemScope itemType="http://schema.org/WPFooter">
      <div className="footer">
        <Link to="/" className="footer__logo">
          <img src={logoImg} width="280" alt="Логотип компании" />
        </Link>
        <div className="footer__contact">
          {/* <a className="footer__phone tel" href="tel:+79683210182">+7 (968) 321-01-82</a> */}
          <a className="footer__mail mail" href="mailto:info@psyterapevt.ru">info@psyterapevt.ru</a>
        </div>
        <div className="footer__links">
          <a href="https://vk.com/polyanskaya_en" target="_blank" rel="nofollow noopener noreferrer">
              <img src={vkontakte} alt="vkontakte" width="53" />
          </a>
          <a href="https://telegram.me/psyterapevtEP" target="_blank" rel="nofollow noopener noreferrer">
            <img src={telegram} alt="telegram" width="53" />
          </a>
          <a href="https://www.youtube.com/channel/UCpC26R1Q29O0Copkf2usuRA" target="_blank" rel="nofollow noopener noreferrer">
            <img src={youtube} alt="youtube" width="50" />
          </a>
          <a href="https://wa.me/79683210182" target="_blank" rel="nofollow noopener noreferrer">
            <img src={whatsapp} alt="whatsapp" width="53" />
          </a>
        </div>
        <div className="footer__docs">
          <a href={process.env.PUBLIC_URL + '/politic.pdf?v=1'} target="_blank" rel="noreferrer">Политика конфиденциальности</a>
          <a href={process.env.PUBLIC_URL + '/personal.pdf?v=1'} target="_blank" rel="noreferrer">Согласие на обработку персональных данных</a>
          <a href={process.env.PUBLIC_URL + '/oferta.pdf?v=1'} target="_blank" rel="noreferrer">Договор оферты</a>
        </div>
      </div>
      <Menu/>
      <p className="footer__copyright">Copyright © 2023-{new Date().getFullYear()} Елена Полянская<br/>Отдельные публикации могут содержать информацию, не предназначенную для пользователей до 18 лет.</p>
    </footer>
  )
}
export default Footer;